<template>
  <div class="container mx-auto px-4 py-12 text-white">
    <h1 class="text-5xl font-['VT323'] mb-12 text-center bg-gradient-to-r from-blue-400 via-blue-500 to-blue-400 bg-clip-text text-transparent">
      Frequently Asked Questions
    </h1>
    
    <div class="max-w-3xl mx-auto space-y-6">
      <div v-for="(faq, index) in faqs" :key="index" 
           class="transform transition-all duration-300 hover:scale-[1.02]">
        <div 
          class="bg-gray-800/50 backdrop-blur-sm border border-blue-500/20 rounded-lg overflow-hidden hover:border-blue-500/40 transition-colors"
          @click="toggleFaq(index)"
        >
          <div class="p-6 cursor-pointer">
            <div class="flex justify-between items-center">
              <h3 class="text-xl font-['VT323'] text-blue-400">{{ faq.question }}</h3>
              <span class="text-2xl text-blue-400 transition-transform duration-300"
                    :class="{ 'rotate-45': !faq.isOpen }">
                {{ faq.isOpen ? '−' : '+' }}
              </span>
            </div>
            <div 
              v-show="faq.isOpen" 
              class="mt-4 text-gray-300 font-['VT323'] leading-relaxed"
            >
              {{ faq.answer }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQPage',
  data() {
    return {
      faqs: [
        {
          question: 'What is FlappyETH?',
          answer: 'FlappyETH is a competitive blockchain game on the Blast network combining Flappy Bird gameplay with prize pools. Players compete in rounds for token rewards, with scores being verified and recorded on the blockchain.',
          isOpen: false
        },
        {
          question: 'How do game rounds work?',
          answer: 'Each round has three phases: 1) Fund Collection - waiting for minimum 100 tokens in the prize pool, 2) Game Running - active gameplay lasting 5 days, and 3) Claim Time - 2-day period where winners can claim rewards. The game automatically transitions between these phases.',
          isOpen: false
        },
        {
          question: 'How are winners determined?',
          answer: 'The top 5 highest scores in each round share the prize pool. Your share is calculated proportionally to your score compared to the total of all top 5 scores. For example, if your score represents 40% of the combined top scores, you\'ll receive 40% of the prize pool.',
          isOpen: false
        },
        {
          question: 'How do I start playing?',
          answer: 'To start playing, you need to hold $FLAPPY tokens and make a small donation. This donation grants you 10 games for the current round. The prize pool is separate from donations and is funded independently. Additional games can be purchased with $FLAPPY tokens.',
          isOpen: false
        },
        {
          question: 'How does score verification work?',
          answer: 'Your highest score is cryptographically signed and verified by the backend. If you make it to the top 5, your score is submitted to the blockchain smart contract by the authorized score submitter during the game phase.',
          isOpen: false
        },
        {
          question: 'When can I claim my rewards?',
          answer: 'If you\'re among the top 5 players, you can claim your rewards during the 2-day Claim Time phase. The smart contract automatically calculates your share based on your score\'s proportion to the total top scores. Unclaimed rewards after the claim period affect the transition to the next round.',
          isOpen: false
        },
        {
          question: 'What happens if I lose connection?',
          answer: 'Each game session is protected by a 15-second authentication token. If you lose connection, your current game will end, but your highest verified score for the round remains secure. You can continue playing with your remaining games after reconnecting.',
          isOpen: false
        },
        {
          question: 'What happens if I don\'t claim my rewards during the claim period?',
          answer: 'Unclaimed rewards affect the transition to the next round. Once the 2-day claim period ends, any unclaimed rewards remain in the contract and the game moves back to the Fund Collection phase.',
          isOpen: false
        },
        {
          question: 'How do I know if a round is active?',
          answer: 'The game displays the current phase (Fund Collection, Game Running, or Claim Time) and remaining time. A round becomes active once the prize pool reaches 100 $FLAPPY tokens, starting the 5-day gameplay period.',
          isOpen: false
        },
        {
          question: 'Can I play multiple times with the same score?',
          answer: 'No, the system only keeps your highest verified score for each round. While you can use all your available games to try improving your score, only your best performance counts for the leaderboard.',
          isOpen: false
        },
        {
          question: 'What happens to my unused games when a round ends?',
          answer: 'Unused games don\'t carry over to the next round. Each round requires a new donation to receive 10 games, or you can purchase additional games with $FLAPPY tokens.',
          isOpen: false
        },
        {
          question: 'How do I track my performance?',
          answer: 'The game tracks your current round score, best score ever, and previous round performance. You can also view the live leaderboard to see your ranking among other players and monitor your potential reward allocation.',
          isOpen: false
        },
        {
          question: 'What happens if there are technical issues during gameplay?',
          answer: 'All scores are securely stored and verified on the blockchain. If technical issues occur, your highest verified score for the round is safe. The 15-second authentication system ensures game integrity while allowing you to reconnect and continue playing.',
          isOpen: false
        },
        {
          question: 'Can I participate in multiple rounds?',
          answer: 'Yes! You can participate in as many rounds as you want. Each new round requires a fresh donation for 10 games, or you can purchase additional games. Your performance history across rounds is tracked and stored.',
          isOpen: false
        }
      ]
    }
  },
  methods: {
    toggleFaq(index) {
      this.faqs[index].isOpen = !this.faqs[index].isOpen
    }
  }
}
</script>

<style scoped>
.container {
  background: radial-gradient(circle at center, rgba(59, 130, 246, 0.1), transparent 70%);
}

h1 {
  text-shadow: 0 0 20px rgba(59, 130, 246, 0.5);
}

.max-w-3xl {
  position: relative;
}

.max-w-3xl::before {
  content: '';
  position: absolute;
  inset: -1px;
  background: linear-gradient(90deg, transparent, rgba(59, 130, 246, 0.2), transparent);
  z-index: -1;
  pointer-events: none;
}
</style> 