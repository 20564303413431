import { createRouter, createWebHistory } from 'vue-router'
import GameView from './views/GameView.vue'
import StatisticsPage from './views/StatisticsPage.vue'
import AboutPage from './views/AboutPage.vue'
import FAQPage from './views/FAQPage.vue'
import ProfilePage from './views/ProfilePage.vue'
import NotFoundPage from './views/NotFoundPage.vue'
import RankingsPage from '@/views/RankingsPage.vue'
import NestPage from '@/views/NestPage.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: GameView
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: StatisticsPage
  },
  {
    path: '/about',
    name: 'About',
    component: AboutPage
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQPage
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfilePage,
    meta: { requiresAuth: true }
  },
  {
    path: '/rankings',
    name: 'Rankings',
    component: RankingsPage
  },
  {
    path: '/nest',
    name: 'Nest',
    component: NestPage
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFoundPage
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// Navigation guard for protected routes
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !localStorage.getItem('walletConnected')) {
    next('/')
  } else {
    next()
  }
})

export default router
