<template>
  <div class="min-h-screen flex flex-col">
    <!-- Pre-launch Screen -->
    <div
      v-if="isLocked"
      class="flex-grow flex items-center justify-center bg-neutral-900 relative"
      :style="{
        backgroundImage: 'url(' + require('@/assets/bg_locked.png') + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }"
    >
      <!-- Dark overlay -->
      <div class="absolute inset-0 bg-black bg-opacity-60"></div>

      <div class="text-center p-8 relative z-10">
        <h1 class="text-4xl font-bold text-custom-orange mb-6">
          MAINTENANCE MODE
        </h1>
        <p class="text-xl text-gray-300 mb-8">Preparing for the launch!</p>
        <div class="flex gap-6 justify-center">
          <a
            href="https://app.baseline.markets/trade/blast/0x1BB5E4Dd6F18E8941fCe273379DF2223cD08C2da"
            target="_blank"
            class="px-6 py-3 bg-custom-orange text-white rounded-lg hover:bg-orange-600 transition-colors"
          >
            Trade on Baseline Markets
          </a>
        </div>
      </div>
    </div>

    <!-- Loading Screen -->
    <div v-else-if="isLoading" class="loading-screen">
      <div class="pixel-runner"></div>
      <div class="progress-bar">
        <div class="progress" :style="{ width: loadingProgress + '%' }"></div>
      </div>
      <p>{{ loadingMessage }}</p>
    </div>

    <!-- Main App Content -->
    <div v-else class="flex-grow overflow-x-hidden bg-black m-8 relative">
      {{ debugRequirements }}
      <div
        class="flex sm:mt-1 md:mt-1 lg:mt-1 flex-wrap gap-4 container-above bg-black p-8">
        <ProgressBarDisplay v-if="web3Address != null" />
        <div
          v-if="!canPlayGame && gameState == 1 && web3Address != null"
          class="requirements-message basis-full flex place-content-center flex-row flex-wrap bg-contain bg-opacity-55 bg-center !border min-h-[525px] bg-[url('@/assets/nano_bg.webp')]"
        >
          <GameRequirements />
        </div>
        <div
          v-else
          id="container"
          class="bg-animated basis-full flex place-content-center flex-col bg-auto bg-center bg-no-repeat bg-opacity-55 !border min-h-[525px] bg-[url('@/assets/nano_bg.webp')]"
        >
          <GameComponent></GameComponent>
        </div>
        <div
          v-if="
            isInitialized &&
            gameState == 1 &&
            web3Address != null &&
            canPlayGame
          "
          class="leaderboard bg-neutral-900 md:basis-1/4 grow sm:basis-full !border-2 min-h-[30px] py-4 px-4"
        >
          <GamesLeft></GamesLeft>
        </div>
        <div
          v-if="isInitialized && gameState == 1 && canPlayGame"
          class="leaderboard bg-neutral-900 md:basis-1/4 grow sm:basis-full !border-2 min-h-[30px] py-4 px-4"
        >
          <AirdropAllocation></AirdropAllocation>
        </div>

        <div
          v-if="isInitialized && gameState == 1 && canPlayGame"
          class="rightbuttonarea bg-neutral-900 md:basis-1/4 grow sm:basis-full !border-2 min-h-[100px] p-4"
        >
          <RightButtonArea></RightButtonArea>
        </div>
        <div
          v-if="isInitialized && gameState == 1"
          class="leaderboard bg-neutral-900 md:basis-1/3 grow sm:basis-full !border-2 min-h-[100px] p-8"
        >
          <LeaderBoard></LeaderBoard>
        </div>
        <div
          v-if="isInitialized && gameState == 1"
          class="leaderboard bg-neutral-900 md:basis-1/3 grow sm:basis-full !border-2 min-h-[100px] p-8"
        >
          <StatsDisplay></StatsDisplay>
        </div>
        <div
          class="leaderboard bg-neutral-900 lg:basis-4/4 md:basis-4/4 grow sm:basis-full !border-2 min-h-[30px] max-w-[100%] py-0 px-0"
        >
          <GameRules />
        </div>
      </div>
      <div class="flex flex-row bg-custom-orange">
        <div class="basis-1/4"></div>
        <div class="basis-1/4 image-container min-h-[150px]">
          <img
            src="assets/gear-img.png"
            alt=""
            class="float-right pr-24"
          />
        </div>
        <div class="basis-1/4 image-container min-h-[150px]">
          <img
            src="assets/gear-img2.png"
            alt=""
            class="float-left pr-24"
          />
        </div>
        <div class="basis-1/4"></div>
      </div>
      <div class="flex flex-wrap gap-3">
        <div
          class="flex-grow !border-2 full-width min-w-[800px] p-24 bg-footer-texture"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import GameComponent from "@/components/GameComponent.vue";
import LeaderBoard from "@/components/LeaderBoard.vue";
import RightButtonArea from "@/components/RightButtonArea.vue";
import GamesLeft from "@/components/GamesLeft.vue";
import AirdropAllocation from "@/components/AirdropAllocation.vue";
import StatsDisplay from "@/components/StatsDisplay.vue";
import GameRequirements from "@/components/GameRequirements.vue";
import GameRules from "@/components/GameRules.vue";
import ProgressBarDisplay from "@/components/ProgressBarDisplay.vue";
import { useUserSessionManager } from "../assets/js/userSessionManager";

const { lastActivity } = useUserSessionManager();
const lastActivityTime = new Date(lastActivity.value * 1000);
const currentTime = new Date();
console.log("Last Activity:", lastActivityTime.toLocaleString());
console.log("Current Time:", currentTime.toLocaleString());
const timeDifference = currentTime - lastActivityTime;
console.log("Time Difference (in milliseconds):", timeDifference);

export default {
  name: "GameView",
  components: {
    ProgressBarDisplay,
    GameRules,
    GameComponent,
    LeaderBoard,
    RightButtonArea,
    GamesLeft,
    AirdropAllocation,
    StatsDisplay,
    GameRequirements,
  },
  data() {
    return {
      isLoading: false,
      loadingProgress: 0,
      loadingMessage: "Connecting to the game world...",
      isLocked: process.env.VUE_APP_LOCKED === "true",
    };
  },
  computed: {
    ...mapState([
      "isInitialized",
      "web3Address",
      "isWalletConnected",
      "gameState",
    ]),
    ...mapGetters(["canPlayGame"]),
    debugRequirements() {
     /*  console.log("Requirements check:", {
        canPlayGame: this.canPlayGame,
        gameState: this.gameState,
        web3Address: this.web3Address,
        shouldShowRequirements:
          !this.canPlayGame && this.gameState == 1 && this.web3Address != null,
      }); */
      return null;
    },
  },
  watch: {
    web3Address(newVal) {
      if (newVal) {
        this.startLoading();
      } else {
        this.isLoading = false;
      }
    },
    isInitialized(newVal) {
      if (newVal) {
        this.stopLoading();
      }
    },
  },
  created() {
    this.loadGameScripts();
  },
  methods: {
    loadScript(src) {
      return new Promise((resolve, reject) => {
        if (document.querySelector(`script[src="${src}"]`)) {
          resolve();
          return;
        }
        const script = document.createElement("script");
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    async loadGameScripts() {
      try {
        await this.loadScript("js/jquery.min.js");
        await this.loadScript("js/jquery.transit.min.js");
        await this.loadScript("js/buzz.min.js");
        await this.loadScript("js/main.js");
        this.$store.commit("SET_GAME_SCRIPTS_LOADED", true);
        // You might want to set a flag or emit an event here
      } catch (error) {
        console.error("Error loading game scripts:", error);
      }
    },
    startLoading() {
      this.isLoading = true;
      this.loadingProgress = 0;
      this.updateLoadingProgress();
    },
    stopLoading() {
      this.loadingProgress = 100;
      setTimeout(() => {
        this.isLoading = false;
      }, 500); // Short delay to show 100% before hiding
    },
    updateLoadingProgress() {
      const interval = setInterval(() => {
        if (this.loadingProgress < 90) {
          this.loadingProgress += Math.random() * 10;
          this.updateLoadingMessage();
        } else if (!this.isInitialized) {
          this.loadingProgress = 90; // Stay at 90% until initialized
        } else {
          clearInterval(interval);
          this.stopLoading();
        }
      }, 200);
    },
    updateLoadingMessage() {
      const messages = [
        "Powering up the game engine...",
        "Generating virtual world...",
        "Calibrating pixel physics...",
        "Tuning retro soundscape...",
        "Preparing web3 features...",
      ];
      this.loadingMessage = messages[Math.floor(this.loadingProgress / 20)];
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");
.old-tv {
  position: relative;
  padding: 2rem;
  border-radius: 1px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6), inset 0 0 8px rgba(255, 255, 255, 0.2);
}

.old-tv::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 1px;
  pointer-events: none;
}

.container-above {
  position: relative;
  z-index: 0;
}

.image-container {
  position: relative;
  margin-top: -50px;
  z-index: 1;
}

.image-container img {
  display: block;
  width: 95%;
  position: absolute;
  left: 10%;
  top: 0;
}
.loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #000;
  color: #fff;
  font-family: "VT323", monospace;
  text-align: center;
  flex-grow: 1;
}

.pixel-runner {
  width: 64px;
  height: 64px;
  background-image: url("@/assets/spriteLoading.png");
  animation: run 0.5s steps(8) infinite;
}

@keyframes run {
  from {
    background-position: 0px;
  }
  to {
    background-position: -512px;
  }
}

.progress-bar {
  width: 200px;
  height: 20px;
  background: #333;
  margin: 20px 0;
  border: 2px solid #fff;
}

.progress {
  height: 100%;
  background: #0f0;
  transition: width 0.2s ease-out;
}

p {
  font-size: 16px;
  margin-top: 20px;
}

/* Add this to ensure footer stays at bottom */
:deep(body) {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

:deep(html) {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

:deep(footer) {
  margin-top: auto;
}

.requirements-message {
  background-size: auto;
  animation: scrollBackground 20s linear infinite;
  background-repeat: no-repeat;
}

.bg-animated {
  background-size: auto;
  animation: scrollBackground 20s linear infinite;
  background-repeat: no-repeat;
}

@keyframes scrollBackground {
  from {
    background-position: 0 center;
  }
  to {
    background-position: 100% center;
  }
}

.cabinet-bg {
  position: relative !important;
  box-shadow: 
    0 0 0 4px rgba(0, 0, 0, 0.8),                    /* Main border */
    0 0 8px 8px rgba(0, 0, 0, 0.6),                  /* Outer glow */
    inset 0 0 30px rgba(0, 0, 0, 0.7),               /* Inner shadow */
    inset 0 0 8px rgba(255, 255, 255, 0.2),          /* Screen glow */
    0 5px 15px 8px rgba(0, 0, 0, 0.8),              /* Bottom shadow */
    0 -2px 15px rgba(255, 255, 255, 0.1)            /* Top highlight */
    !important;
  background-color: #000 !important;                 /* Dark background */
  margin: 2rem !important;                           /* Add some spacing */
  padding: 2rem !important;                          /* Inner padding */
  border-radius: 8px !important;                     /* Slightly rounded corners */
  border: 2px solid rgba(255, 255, 255, 0.2) !important; /* Adding visible border */
}
</style>
