<template>
  <div class="container sm:w-full mx-auto px-4 py-12">
    <h1 class="text-5xl font-['VT323'] mb-12 text-center bg-gradient-to-r from-blue-400 via-blue-500 to-blue-400 bg-clip-text text-transparent">
    </h1>
    <PrizeDistribution />
  </div>
</template>

<script>
import PrizeDistribution from '@/components/PrizeDistribution.vue'

export default {
  name: 'RulesPage',
  components: {
    PrizeDistribution
  }
}
</script>

<style scoped>
.container {
  background: radial-gradient(circle at center, rgba(59, 130, 246, 0.1), transparent 70%);
}

h1 {
  text-shadow: 0 0 20px rgba(59, 130, 246, 0.5);
}
</style> 