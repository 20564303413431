import apiService from '../../assets/js/apiService';

export default {
  namespaced: true,
  
  state: {
    tradingFees: {
      balance: { WETH: 0, FLAPPY: 0 },
      weekly: { WETH: 0, FLAPPY: 0 },
      total: { WETH: 0, FLAPPY: 0 }
    },
    fundManager: {
      balance: { WETH: 0, FLAPPY: 0 },
      weekly: { WETH: 0, FLAPPY: 0 },
      total: { WETH: 0, FLAPPY: 0 }
    },
    gameRevenue: {
      balance: { WETH: 0, FLAPPY: 0 },
      weekly: { WETH: 0, FLAPPY: 0 },
      total: { WETH: 0, FLAPPY: 0 }
    }
  },

  mutations: {
    SET_REVENUE(state, { data }) {
      state.tradingFees = data.tradingFees;
      state.fundManager = data.fundManager;
      state.gameRevenue = data.gameRevenue;
    }
  },

  actions: {
    async fetchRevenue({ commit }) {
      try {
        const response = await apiService.getRevenueStats();
        if (response.success) {
          commit('SET_REVENUE', { data: response.data });
        }
      } catch (error) {
        console.error('Error fetching revenue:', error);
      }
    }
  }
}; 