<template>
  <div class="statistics-container bg-gray-800 rounded-lg p-6 my-4">
    <h2 class="text-2xl font-bold text-white mb-6 text-center">Game Statistics</h2>
    
    <!-- Overall Stats Grid -->
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
      <div class="stat-card">
        <h3 class="text-gray-400">Total Players</h3>
        <p class="text-2xl font-bold text-white">1,337</p>
      </div>
      <div class="stat-card">
        <h3 class="text-gray-400">Total Rounds</h3>
        <p class="text-2xl font-bold text-white">42</p>
      </div>
      <div class="stat-card">
        <h3 class="text-gray-400">Average Score</h3>
        <p class="text-2xl font-bold text-white">69</p>
      </div>
    </div>

    <!-- Last 24h Stats -->
    <div class="bg-gray-900 rounded-lg p-4 mb-6">
      <h3 class="text-xl text-white mb-4">Last 24 Hours</h3>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="stat-card-secondary">
          <h4 class="text-gray-400">Active Players</h4>
          <p class="text-xl text-white">420</p>
        </div>
        <div class="stat-card-secondary">
          <h4 class="text-gray-400">Rounds Played</h4>
          <p class="text-xl text-white">8,008</p>
        </div>
      </div>
    </div>

    <!-- Current Round Stats -->
    <div class="bg-gray-900 rounded-lg p-4">
      <h3 class="text-xl text-white mb-4">Current Round</h3>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="stat-card-secondary">
          <h4 class="text-gray-400">Submitted Scores</h4>
          <p class="text-xl text-white">404</p>
        </div>
        <div class="stat-card-secondary">
          <h4 class="text-gray-400">Unsubmitted Scores</h4>
          <p class="text-xl text-white">42</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatisticsView'
}
</script>

<style scoped>
.stat-card {
  @apply bg-gray-700 rounded-lg p-4 text-center;
}

.stat-card-secondary {
  @apply bg-gray-800 rounded-lg p-4 text-center;
}
</style>
