<template>
  <div class="container mx-auto px-4 py-8 text-white">
    <div class="max-w-3xl mx-auto">
      <div class="bg-gray-800 rounded-lg p-8">
        <div class="flex items-center mb-8">
          <div class="mr-6">
            <h1 class="text-2xl font-bold mb-2">{{ shortenAddress }}</h1>
            <p class="text-gray-400">Player Profile</p>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div class="bg-gray-700 rounded-lg p-6">
            <h2 class="text-lg font-bold mb-4">Statistics</h2>
            <div class="space-y-3">
              <div class="flex justify-between">
                <span>Best Score:</span>
                <span>{{ bestScore }}</span>
              </div>
              <div class="flex justify-between">
                <span>Games Played:</span>
                <span>{{ gamesPlayed }}</span>
              </div>
              <div class="flex justify-between">
                <span>Games Left Today:</span>
                <span>{{ gamesLeft }}</span>
              </div>
            </div>
          </div>

          <div class="bg-gray-700 rounded-lg p-6">
            <h2 class="text-lg font-bold mb-4">Current Round</h2>
            <div class="space-y-3">
              <div class="flex justify-between">
                <span>Round Score:</span>
                <span>{{ currentRoundScore }}</span>
              </div>
              <div class="flex justify-between">
                <span>Round Rank:</span>
                <span>{{ currentRoundRank }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ProfilePage',
  computed: {
    ...mapState(['web3Address', 'gamesLeft']),
    shortenAddress() {
      if (!this.web3Address) return ''
      return `${this.web3Address.slice(0, 6)}...${this.web3Address.slice(-4)}`
    },
    bestScore() {
      return this.$store.state.bestScore || 0
    },
    gamesPlayed() {
      return this.$store.state.gamesPlayed || 0
    },
    currentRoundScore() {
      return this.$store.state.currentRoundScore || 0
    },
    currentRoundRank() {
      return this.$store.state.currentRoundRank || '-'
    }
  }
}
</script> 