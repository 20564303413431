<template>
  <div class="flex flex-col items-center justify-center min-h-screen text-white">
    <h1 class="text-6xl font-bold mb-4">404</h1>
    <p class="text-xl mb-8">Oops! Page not found</p>
    <router-link 
      to="/" 
      class="px-6 py-3 bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors"
    >
      Return Home
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'NotFoundPage'
}
</script>
