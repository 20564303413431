<template>
  <footer class="bg-gray-900 bg-opacity-50 p-5 mt-auto">
    <div class="container mx-auto px-4">
      <div class="flex flex-col items-center justify-center text-center">
        <div class="flex items-center space-x-2 mb-2">
          <span class="text-sm text-gray-400">Built with</span>
          <svg 
            class="w-4 h-4 text-red-500 animate-pulse" 
            fill="currentColor" 
            viewBox="0 0 20 20"
          >
            <path 
              fill-rule="evenodd" 
              d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" 
              clip-rule="evenodd" 
            />
          </svg>
          <span class="text-sm text-gray-400">by 
            <a 
              href="https://devsbased.com" 
              target="_blank" 
              rel="noopener noreferrer" 
              class="hover:text-blue-400 transition-colors duration-200"
            >devsbased</a>
          </span>
        </div>
        <p class="text-xs text-gray-500 italic">
          "Where <span class="text-blue-400">Wisdom</span> guides the code, 
          <span class="text-blue-400">Strength</span> secures the chain, and 
          <span class="text-blue-400">Beauty</span> lives in DeFi's harmony."
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterDisplay'
}
</script>

<style scoped>
footer {
  border-top: 1px solid rgba(59, 130, 246, 0.1);
  backdrop-filter: blur(5px);
}
</style>
