<template>
  <div class="container mx-auto px-4 py-8">
    <StatisticsView />
  </div>
</template>

<script>
import StatisticsView from '../components/StatisticsView.vue'

export default {
  name: 'StatisticsPage',
  components: {
    StatisticsView
  }
}
</script>

<style scoped>
.container {
  background-color: #1a202c;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}
</style>
