<template>
  <div class="progress-bar-container mb-6">
    <div v-if="isLoading" class="loading-container">
      <span class="loading-text">Loading</span>
    </div>
    <div v-else-if="shouldHideCountdown" class="text-center text-gray-500">
      Waiting for minimum prize pool...
    </div>
    <div v-else class="progress-wrapper" :class="{ 'preparing-round': isPreparingRound }">
      <svg viewBox="0 0 100 8" class="w-full h-14" preserveAspectRatio="none">
        <defs>
          <linearGradient id="progressGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style="stop-color:#3B82F6;stop-opacity:1" />
            <stop offset="100%" style="stop-color:#60A5FA;stop-opacity:1" />
          </linearGradient>
        </defs>
        
        <rect
          x="0"
          y="2"
          width="100%"
          height="14"
          fill="#1F2937"
          class="background-bar"
        />
        <rect
          x="0"
          y="2"
          :width="`${progressWidth}%`"
          height="8"
          fill="url(#progressGradient)"
          class="progress-bar"
        />
        <text
          x="50%"
          y="6"
          text-anchor="middle"
          class="time-text"
          fill="#ffffff"
          filter="url(#textGlow)"
        >{{ stateText }} {{ displayText }}</text>
      </svg>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ProgressBarWithTimeDisplay",
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState({
      gameState: (state) => state.gameState,
      countdown: (state) => state.countdown,
      gameDuration: (state) => state.gameDuration,
      claimDuration: (state) => state.claimDuration,
      transitionDuration: (state) => state.transitionDuration,
      fundingDuration: (state) => state.fundingDuration,
      isTransitioningToClaimTime: (state) => state.isTransitioningToClaimTime,
      isTransitioningToFundingState: (state) => state.isTransitioningToFundingState,
      prizePool: (state) => state.prizePool,
    }),
    displayText() {
      return this.countdown?.displayText || "0:00";
    },
    progressWidth() {
      // If there's no countdown value, return 0
      if (!this.countdown) {
        console.log('No countdown data available');
        return 0;
      }
      
      return this.countdown.progressValue || 0;
    },
    stateText() {
      if (this.gameState === 0 && this.prizePool >= 100) return "Smart Contract Prepares the Round";
      if (this.isTransitioningToClaimTime) return "Finalizing Round:";
      if (this.isTransitioningToFundingState) return "New Funding Starts in:";
      if (this.gameState === 2) return "Claim Period Ends in:";
      if (this.countdown?.type === 'funding') return "Funding Period Ends in:";
      return "Round Ends in:";
    },
    shouldHideCountdown() {
      return this.gameState === 0 && this.prizePool < 100;
    },
    isPreparingRound() {
      return this.gameState === 0 && this.prizePool >= 100;
    },
  },
  async mounted() {
    this.$store.dispatch('initializeCountdown');
    this.isLoading = false;
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
</style>

<style scoped>
/* Keep base styles */
.progress-bar-container {
  width: 100%;
  max-height: 34px;
  position: relative;
  padding: 0;
}

.progress-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.background-bar {
  opacity: 0.8;
}

.progress-bar {
  transition: all 0.5s linear;
  transform-origin: left;
  will-change: width;
}

.time-text {
  font-family: 'Press Start 2P', monospace;
  font-size: 1.5px;
  letter-spacing: 0px;
  dominant-baseline: auto;
  text-transform: uppercase;
  font-weight: normal;
  text-shadow: 
    -1px -1px 0 rgba(0,0,0,0.7),
    1px -1px 0 rgba(0,0,0,0.7),
    -1px 1px 0 rgba(0,0,0,0.7),
    1px 1px 0 rgba(0,0,0,0.7),
    0 0 4px rgba(96, 165, 250, 0.5);
}

/* Enhanced animations for preparing round */
.preparing-round .progress-bar {
  position: relative;
  overflow: hidden;
  filter: brightness(1.4);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

.preparing-round .progress-bar::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
  animation: shine 1.5s ease-in-out infinite;
}

@keyframes shine {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.preparing-round .time-text {
  animation: glow 1.5s ease-in-out infinite;
}

@keyframes glow {
  0%, 100% {
    filter: brightness(1);
    text-shadow: 
      -1px -1px 0 rgba(0,0,0,0.7),
      1px -1px 0 rgba(0,0,0,0.7),
      -1px 1px 0 rgba(0,0,0,0.7),
      1px 1px 0 rgba(0,0,0,0.7),
      0 0 8px rgba(134, 239, 172, 0.8);
  }
  50% {
    filter: brightness(1.5);
    text-shadow: 
      -1px -1px 0 rgba(0,0,0,0.7),
      1px -1px 0 rgba(0,0,0,0.7),
      -1px 1px 0 rgba(0,0,0,0.7),
      1px 1px 0 rgba(0,0,0,0.7),
      0 0 15px rgba(134, 239, 172, 1);
  }
}
</style>