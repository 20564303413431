<template>
  <div class="container mx-auto px-4 py-12 text-white">
    <h1 class="text-5xl font-['VT323'] mb-12 text-center bg-gradient-to-r from-blue-400 via-blue-500 to-blue-400 bg-clip-text text-transparent">
      Rankings
    </h1>
    
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-8">
      <!-- Current Round Top 20 -->
      <div class="bg-gray-800/50 backdrop-blur-sm border border-blue-500/20 rounded-lg p-6">
        <h2 class="text-3xl font-['VT323'] text-blue-400 mb-6">Current Round Top 20</h2>
        <div class="space-y-2">
          <div v-for="(player, index) in currentRoundTop20" :key="index"
               class="flex items-center justify-between p-3 bg-gray-900/50 rounded-lg border border-blue-500/10 hover:border-blue-500/30 transition-colors">
            <div class="flex items-center space-x-4">
              <span class="text-xl font-['VT323'] text-blue-400 w-8">#{{ index + 1 }}</span>
              <span class="font-['VT323'] text-gray-300">{{ shortenAddress(player.address) }}</span>
            </div>
            <span class="font-['VT323'] text-blue-400">{{ player.score }}</span>
          </div>
        </div>
      </div>

      <!-- All-Time Top 100 -->
      <div class="bg-gray-800/50 backdrop-blur-sm border border-blue-500/20 rounded-lg p-6">
        <h2 class="text-3xl font-['VT323'] text-blue-400 mb-6">All-Time Top 100</h2>
        <div class="space-y-2">
          <div v-for="(player, index) in allTimeTop100" :key="index"
               class="flex items-center justify-between p-3 bg-gray-900/50 rounded-lg border border-blue-500/10 hover:border-blue-500/30 transition-colors">
            <div class="flex items-center space-x-4">
              <span class="text-xl font-['VT323'] text-blue-400 w-8">#{{ index + 1 }}</span>
              <span class="font-['VT323'] text-gray-300">{{ shortenAddress(player.address) }}</span>
            </div>
            <span class="font-['VT323'] text-blue-400">{{ player.score }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RankingsPage',
  data() {
    return {
      currentRoundTop20: Array(20).fill().map(() => ({
        address: '0x1234567890123456789012345678901234567890',
        score: Math.floor(Math.random() * 1000)
      })),
      allTimeTop100: Array(100).fill().map(() => ({
        address: '0x1234567890123456789012345678901234567890',
        score: Math.floor(Math.random() * 10000)
      }))
    }
  },
  methods: {
    shortenAddress(address) {
      return `${address.slice(0, 6)}...${address.slice(-4)}`
    }
  }
}
</script>

<style scoped>
.container {
  background: radial-gradient(circle at center, rgba(59, 130, 246, 0.1), transparent 70%);
}

h1 {
  text-shadow: 0 0 20px rgba(59, 130, 246, 0.5);
}

/* Add smooth scrolling for the ranking lists */
.space-y-2 {
  max-height: 70vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(59, 130, 246, 0.5) transparent;
}

/* Custom scrollbar styling */
.space-y-2::-webkit-scrollbar {
  width: 6px;
}

.space-y-2::-webkit-scrollbar-track {
  background: transparent;
}

.space-y-2::-webkit-scrollbar-thumb {
  background-color: rgba(59, 130, 246, 0.5);
  border-radius: 3px;
}
</style> 