<template>
  <div class="container mx-auto px-4 py-8 text-white">
    <h1 class="text-4xl font-bold mb-8">About FlappyETH</h1>
    <div class="max-w-3xl mx-auto">
      <p class="mb-6">
        FlappyETH is a blockchain-based gaming platform that combines the classic 
        Flappy Bird gameplay with Web3 technology.
      </p>
      <p class="mb-6">
        Players can compete for rewards, earn tokens, and participate in 
        regular tournaments while enjoying the nostalgic gameplay.
      </p>
      <div class="bg-gray-800 rounded-lg p-6 mt-8">
        <h2 class="text-2xl font-bold mb-4">How It Works</h2>
        <ul class="list-disc list-inside space-y-3">
          <li>Connect your Web3 wallet to start playing</li>
          <li>Use your daily free games or purchase additional plays</li>
          <li>Compete for high scores and earn rewards</li>
          <li>Join tournaments and special events</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutPage'
}
</script> 