import axios from "axios";
import store from "../../store"; // Assuming you have access to the Vuex store instance

const checkAuth = () => {
  const token = localStorage.getItem("userToken");
  if (!token) {
    throw new Error("No authentication token found");
  }
};

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  // other configurations as needed
});

apiClient.interceptors.request.use(
  (config) => {
    const isGameScoreEndpoint = config.url.includes("/api/increment");
    let token;

    if (isGameScoreEndpoint) {
      token = localStorage.getItem("gameToken");
    } else {
      token = localStorage.getItem("userToken");
      //console.log("Using user token for request:", config.url);
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      //console.log("Token added to request headers");
    } else {
      console.warn(
        `No ${
          isGameScoreEndpoint ? "game" : "user"
        } token available for request to ${config.url}`
      );
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default {
  logGameStart() {
    checkAuth();
    return apiClient.post("/api/startGame", {
      web3Address: store.state.web3Address,
    });
  },

  logObstaclePassed(data) {
    return apiClient.post("/api/increment", data);
  },

  logIncrement() {
    return apiClient.post("/api/increment", {
      web3Address: store.state.web3Address,
    });
  },

  async logGameEnd() {
    apiClient.post("/api/endGame", {
      web3Address: store.state.web3Address,
    });
    await this.getLeftGames();
    await this.getHighestScore();
  },

  async getLeftGames() {
    checkAuth();
    try {
      const response = await apiClient.get("/api/gamesLeft", {
        params: {
          // Use the `params` property to include query parameters
          web3Address: store.state.web3Address,
        },
      });

      const leftGames = response.data.leftGames; // Assuming the response contains a field 'leftGames'

      // Commit the fetched number of left games to the Vuex store
      store.commit("UPDATE_GAMES_LEFT", leftGames);

      return response; // Return the response if needed
    } catch (error) {
      console.error("Error fetching left games:", error);
      throw error; // Rethrow the error for handling in the caller
    }
  },

  async getHighestScore() {
    checkAuth();
    try {
      const response = await apiClient.get("/api/highestScore", {
        params: {
          // Use the `params` property to include query parameters
          web3Address: store.state.web3Address,
        },
      });

      const highestScore = response.data.currentRoundScore; // Assuming the response contains a field 'leftGames'

      // Commit the fetched number of left games to the Vuex store
      store.commit("UPDATE_HIGHEST_SCORE", highestScore);

      return response; // Return the response if needed
    } catch (error) {
      console.error("Error fetching highest score:", error);
      throw error; // Rethrow the error for handling in the caller
    }
  },

  async getLeaderboard() {
    checkAuth();
    try {
      const response = await apiClient.get("/api/leaderboard", {});

      const leaderboard = response.data.allTimeLeaderboardd; // Assuming the response contains a field 'leftGames'

      // Commit the fetched number of left games to the Vuex store
      store.commit("UPDATE_LEADERBOARD", leaderboard);

      return response; // Return the response if needed
    } catch (error) {
      console.error("Error fetching leaderboard:", error);
      throw error; // Rethrow the error for handling in the caller
    }
  },
  async getUser() {
    checkAuth();
    try {
      const response = await apiClient.get("/api/getUser", {
        params: {
          // Use the `params` property to include query parameters
          web3Address: store.state.web3Address,
        },
      });
      return response; // Return the response if needed
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error; // Rethrow the error for handling in the caller
    }
  },

  async verifySignature(signature, originalValue, web3Address) {
    try {
      const response = await apiClient.post("/api/verifySignature", {
        web3Address: web3Address, // The user's Ethereum address
        signature: signature, // The signature to verify
        originalValue: originalValue, // The original value that was signed
      });

      // The response from the backend will indicate success or failure of the verification
      return response.data;
    } catch (error) {
      console.error("Error verifying signature:", error);
      throw error; // Rethrow the error for handling in the caller
    }
  },

  async getCurrentPotentialAllocation() {
    checkAuth();
    try {
      const response = await apiClient.get("/api/potential-allocation", {
        params: {
          web3Address: store.state.web3Address,
        },
      });
      return response.data.percentageAllocation; // Adjust this based on your API response structure
    } catch (error) {
      console.error("Error fetching current potential allocation:", error);
      throw error;
    }
  },

  async getRoundParticipants() {
    checkAuth();
    try {
      const response = await apiClient.get("/api/round-participants");
      return response.data.participants;
    } catch (error) {
      console.error("Error fetching round participants:", error);
      throw error;
    }
  },

  async getCurrentRoundTopScores() {
    checkAuth();
    try {
      const response = await apiClient.get("/api/current-round-top-scores");
      return response.data.scores; // Match the backend response structure
    } catch (error) {
      console.error("Error fetching current round top scores:", error);
      throw error;
    }
  },

  getGameStatistics() {
    return apiClient.get('/api/statistics');
  },

  async buyGames(web3Address, amount, transactionHash) {
    checkAuth();
    try {
      const response = await apiClient.post('/api/games/buy', {
        web3Address,
        amount,
        transactionHash
      });
      return response.data;
    } catch (error) {
      console.error("Error buying games:", error);
      throw error;
    }
  },

  async getFlappyPrice() {
    try {
      const response = await apiClient.get('/api/flappy-price');
      return response.data.price; // Returns just the price string
    } catch (error) {
      console.error("Error fetching Flappy price:", error);
      throw error;
    }
  },

  async getRevenueStats() {
    try {
      const response = await apiClient.get('/api/revenue-stats');
      return response.data;
    } catch (error) {
      console.error("Error fetching revenue stats:", error);
      throw error;
    }
  },
};
