<template>
  <div class="deposit-container">
    <h3 class="text-xl font-semibold text-yellow-500 mb-2">Deposit Funds</h3>
    <div class="flex items-center justify-center space-x-2">
      <input
        v-model="depositAmount"
        type="number"
        placeholder="Amount to deposit"
        class="bg-gray-700 text-white px-3 py-2 rounded"
        :disabled="isDepositing"
      />
      <button
        @click="handleDeposit"
        class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out relative overflow-hidden min-w-[150px]"
        :disabled="isDepositing || !web3Address"
      >
        <span 
          class="inline-flex items-center space-x-2"
          :class="{'opacity-0': isDepositing}"
        >
          {{ buttonText }}
        </span>
        
        <span 
          v-if="isDepositing" 
          class="absolute inset-0 flex items-center justify-center space-x-2"
        >
          <svg 
            class="animate-spin h-5 w-5 text-white" 
            xmlns="http://www.w3.org/2000/svg" 
            fill="none" 
            viewBox="0 0 24 24"
          >
            <circle 
              class="opacity-25" 
              cx="12" 
              cy="12" 
              r="10" 
              stroke="currentColor" 
              stroke-width="4"
            />
            <path 
              class="opacity-75" 
              fill="currentColor" 
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
          <span class="ml-2">{{ buttonText }}</span>
        </span>
      </button>
    </div>
    <p v-if="depositError" class="text-red-500 mt-2">{{ depositError }}</p>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { pollTransactionReceipt } from '../utils/alchemy';
const toast = useToast();
export default {
  name: "DepositComponent",
  setup() {
    const store = useStore();
    const depositAmount = ref("");
    const isDepositing = ref(false);
    const depositError = ref("");
    const web3 = store.state.web3;
    const web3Address = computed(() => store.state.web3Address);
    const contract = computed(() => store.state.GameContract);
    const tokenContract = computed(() => store.state.W3MContract);
    const depositStage = ref('idle'); // 'idle', 'approving', 'approved', 'depositing'
    const alchemyUrl = computed(() => store.state.alchemyUrl);
    const handleDepositError = (error) => {
      console.error("Deposit error:", error);
      if (error.message.includes("User denied")) {
        depositError.value = "Transaction was cancelled.";
      } else if (error.message.includes("Approval transaction failed")) {
        depositError.value = "Token approval failed. Please try again.";
      } else if (error.message.includes("Deposit transaction failed")) {
        depositError.value = "Deposit failed, but tokens were approved. You may need to reset your allowance.";
      } else if (error.message.includes("reorged")) {
        depositError.value = "Transaction was reorged. Please try again.";
      } else {
        depositError.value = "An error occurred during the deposit process. Please try again.";
      }
      toast.error(depositError.value);
    };

    const handleDeposit = async () => {
      console.log("1. Starting deposit process...");
      if (!depositAmount.value || isNaN(depositAmount.value)) {
        depositError.value = "Please enter a valid amount";
        return;
      }

      isDepositing.value = true;
      depositError.value = "";
      depositStage.value = 'approving';

      try {
        const amount = web3.utils.toWei(depositAmount.value, "ether");
        const spenderAddress = contract.value._address;
        toast.info("Please confirm the approval in your wallet...");

        // Prepare transaction parameters for approval
        const approvalTxParams = {
          from: web3Address.value,
          to: tokenContract.value.options.address,
          data: tokenContract.value.methods.approve(spenderAddress, amount).encodeABI(),
          gas: "200000"
        };

        // Send approval transaction
        const approvalTxHash = await window.ethereum.request({
          method: "eth_sendTransaction",
          params: [approvalTxParams],
        });

        console.log("📨 Approval transaction sent, waiting for confirmation:", approvalTxHash);
        // Poll for deposit receipt
        let confirmedReceipt = await pollTransactionReceipt(approvalTxHash, alchemyUrl.value);

        if (!confirmedReceipt || !confirmedReceipt.status) {
          throw new Error("Approval failed");
        }

        console.log("🎉 Approval confirmed:", approvalTxHash);
        toast.success(depositAmount.value + " Tokens approved");
        depositStage.value = 'depositing';
        toast.info("Please confirm the deposit in your wallet...");

        // Prepare transaction parameters for deposit
        const depositTxParams = {
          from: web3Address.value,
          to: contract.value.options.address,
          data: contract.value.methods.depositFunds(amount).encodeABI(),
        };

        // Send deposit transaction
        const depositTxHash = await window.ethereum.request({
          method: "eth_sendTransaction",
          params: [depositTxParams],
        });

        console.log("📨 Deposit transaction sent, waiting for confirmation:", depositTxHash);

        // Poll for deposit receipt
        confirmedReceipt = await pollTransactionReceipt(depositTxHash, alchemyUrl.value);

        if (!confirmedReceipt || !confirmedReceipt.status) {
          throw new Error("Deposit transaction failed");
        }

        console.log("🎉 Deposit confirmed:", confirmedReceipt);
        depositStage.value = 'idle';
        toast.success("Funds deposited successfully!");

        await store.dispatch("fetchPrizePool");
        await store.dispatch("fetchUserBalance");
        depositAmount.value = "";

      } catch (error) {
        console.error("❌ Deposit process failed:", error);
        handleDepositError(error);
      } finally {
        isDepositing.value = false;
        depositStage.value = 'idle';
      }
    };

    const buttonText = computed(() => {
      switch (depositStage.value) {
        case 'approving':
          return 'Approving...';
        case 'approved':
          return 'Approved! Depositing...';
        case 'depositing':
          return 'Depositing...';
        default:
          return 'Deposit';
      }
    });

    return {
      depositAmount,
      isDepositing,
      depositError,
      handleDeposit,
      handleDepositError,
      web3Address,
      buttonText,
    };
  },
};
</script>

<style scoped>
.deposit-container {
  /* Add any specific styles for the deposit container */
}
</style>
