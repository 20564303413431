<template>
  <div
    class="flex sm:place-content-center md:place-content-end lg:place-content-end"
  >
    <div v-if="account.connected" class="flex md:justify-end lg:justify-end items-center space-x-4">
      <div class="hidden md:flex items-center bg-blue-500/10 rounded-lg px-3 py-1">
        <span class="text-md text-blue-400 font-['VT323']">{{ formatBalance }} $FLAPPY</span>
      </div>
      <button class="btn btn-blue" @click="accountDetails()">
        {{ formatAddress(account.address) }}
      </button>
    </div>
    <div v-else class="flex grow sm:justify-end md:justify-end lg:justify-end">
      <button class="btn btn-green" @click="handleConnect()">
        Connect wallet
      </button>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
const previousAddress = ref(null);
import { watch, computed, ref } from "vue";
import {
  account,
  accountDetails,
  connect,
  disconnect,
} from "@kolirt/vue-web3-auth";

const store = useStore();

const isConnected = computed(() => account.connected);
const formatBalance = computed(() => {
  const balance = store.state.userBalance;
  return balance ? parseFloat(balance).toFixed(2) : '0.00';
});

async function initializeWallet() {
  try {
    await store.dispatch("initializeContracts");
    await store.dispatch("fetchInitialData");
  } catch (error) {
    console.error("Failed to initialize or fetch data:", error);
    handleDisconnect();
  }
}

watch(isConnected, async (newValue) => {
  if (newValue) {
    await initializeWallet();
  } else {
    handleDisconnect();
  }
});

watch(
  () => account.address,
  async (newAddress, oldAddress) => {
    if (oldAddress && newAddress !== oldAddress) {
      console.log("Wallet switched, reinitializing...");
      await store.dispatch("clearUserData");
      await initializeWallet();
    }
    previousAddress.value = newAddress;
  }
);

function formatAddress(address) {
  return `${address.substring(0, 4)}...${address.substring(
    address.length - 4
  )}`;
}

async function handleDisconnect() {
  disconnect();
  localStorage.removeItem("walletConnected");
  localStorage.removeItem("userToken");
  await store.dispatch("clearUserData");
}

async function handleConnect() {
  try {
    await connect();
    await new Promise((resolve, reject) => {
      const timeout = setTimeout(
        () => reject(new Error("Connection timeout")),
        Number(process.env.VUE_APP_WALLET_CONNECT_TIMEOUT)
      );
      const checkConnection = setInterval(() => {
        if (account.connected) {
          clearInterval(checkConnection);
          clearTimeout(timeout);
          resolve();
        }
      }, 1000);
    });

    if (account.connected) {
      localStorage.setItem("walletConnected", "true");
    }
  } catch (error) {
    console.error("Error connecting:", error);
  }
}
</script>

<style scoped>
.btn {
  @apply font-['VT323'] py-1 px-3 rounded;
}
.btn-blue {
  @apply bg-blue-500/10 text-blue-400 transition-colors;
}
.btn-green {
  @apply bg-green-500/10 text-green-400 hover:bg-green-500/20 transition-colors;
}
</style>
