<template>
  <div class="prize-distribution w-full mx-auto relative px-2 sm:px-4 md:px-8">
    <!-- Top Pool -->
    <div ref="topPool" class="top-pool w-full bg-gradient-to-b from-gray-900/95 to-gray-900/80 backdrop-blur-sm rounded-xl p-4 sm:p-6 md:p-8 border border-blue-500/20 relative z-20 shadow-xl">
      <h2 class="text-white font-['VT323'] text-[2rem] sm:text-[2.25rem] md:text-[2.5rem] text-center mb-6 sm:mb-8 md:mb-10 text-shadow-glow">
        Flappy Revenue Nest
      </h2>
      
      <!-- Revenue Cards Grid -->
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 sm:gap-6 md:gap-8">
        <!-- Trading Fees -->
        <div class="sub-item w-full bg-gradient-to-br from-gray-900/70 to-gray-800/50 rounded-xl border border-blue-500/10 hover:border-blue-400/30 transition-all duration-300 shadow-lg hover:shadow-blue-500/5">
          <!-- Card Header -->
          <div class="p-4 border-b border-blue-500/10 bg-gray-900/30 h-[132px] flex flex-col justify-center">
            <h3 class="text-white font-['VT323'] text-[1.5rem] sm:text-[1.75rem] text-center text-shadow-sm whitespace-nowrap">
              Feather Tax
            </h3>
            <p class="text-blue-400/80 text-[1.1rem] sm:text-[1rem] md:text-[1rem] text-center font-['VT323'] mt-1 line-clamp-2">
              Revenue from trading fees
            </p>
          </div>
          
          <!-- Card Content -->
          <div class="p-4">
            <!-- Current Balance Box -->
            <div class="bg-gray-900/40 rounded-lg border border-blue-500/5 p-4 mb-4">
              <p class="text-[0.8rem] text-blue-400/60 text-center uppercase tracking-wider mb-3">
                Current Balance
              </p>
              <div class="space-y-3">
                <div class="flex justify-between items-center">
                  <span class="text-[1.1rem] sm:text-[1.2rem] text-green-400 font-['VT323']">WETH</span>
                  <span class="text-[1.1rem] sm:text-[1.2rem] text-green-400 font-['VT323']">
                    {{ formatTokenAmount(tradingRevenue.balance?.WETH || 0) }}
                  </span>
                </div>
                <div class="flex justify-between items-center">
                  <span class="text-[1.1rem] sm:text-[1.2rem] text-green-400 font-['VT323']">FLAPPY</span>
                  <span class="text-[1.1rem] sm:text-[1.2rem] text-green-400 font-['VT323']">
                    {{ formatTokenAmount(tradingRevenue.balance?.FLAPPY || 0) }}
                  </span>
                </div>
                <div class="text-center pt-3 border-t border-blue-500/10">
                  <p class="text-[0.9rem] text-blue-400/80 font-semibold">
                    ≈ ${{ formatNumber(calculateValue(tradingRevenue.balance)) }}
                  </p>
                </div>
              </div>
            </div>

            <!-- Stats Grid -->
            <div class="grid grid-cols-2 gap-3">
              <div class="bg-gray-900/30 rounded-lg border border-blue-500/5 p-3">
                <p class="text-[0.8rem] text-blue-400/60 text-center uppercase tracking-wider mb-2">
                  Last 7 Days
                </p>
                <p class="text-[1.1rem] sm:text-[1.2rem] text-green-400 font-['VT323'] text-center">
                  +${{ formatNumber(tradingRevenue.weekly) }}
                </p>
              </div>
              <div class="bg-gray-900/30 rounded-lg border border-blue-500/5 p-3">
                <p class="text-[0.8rem] text-blue-400/60 text-center uppercase tracking-wider mb-2">
                  Lifetime
                </p>
                <p class="text-[1.1rem] sm:text-[1.2rem] text-green-400 font-['VT323'] text-center">
                    +${{ formatNumber(tradingRevenue.total) }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Fund Manager -->
        <div class="sub-item w-full bg-gradient-to-br from-gray-900/70 to-gray-800/50 rounded-xl border border-blue-500/10 hover:border-blue-400/30 transition-all duration-300 shadow-lg hover:shadow-blue-500/5">
          <div class="p-4 border-b border-blue-500/10 bg-gray-900/30 h-[132px] flex flex-col justify-center">
            <h3 class="text-white font-['VT323'] text-[1.5rem] sm:text-[1.75rem] text-center text-shadow-sm whitespace-nowrap">
              Nest Keeper's Share
            </h3>
            <p class="text-blue-400/80 text-[1.1rem] sm:text-[1rem] md:text-[1rem] text-center font-['VT323'] mt-1 line-clamp-2">
                Earnings from <a href="https://flappyeth.com/feedthebird" class="pulsating-link" target="_blank" rel="noopener noreferrer">Toaster's fund</a> management
            </p>
          </div>
          
          <div class="p-4">
            <div class="bg-gray-900/40 rounded-lg border border-blue-500/5 p-4 mb-4">
              <p class="text-[0.8rem] text-blue-400/60 text-center uppercase tracking-wider mb-3">
                Current Balance
              </p>
              <div class="space-y-3">
                <div class="flex justify-between items-center">
                  <span class="text-[1.1rem] sm:text-[1.2rem] text-green-400 font-['VT323']">WETH</span>
                  <span class="text-[1.1rem] sm:text-[1.2rem] text-green-400 font-['VT323']">
                    {{ formatTokenAmount(toasterRevenue.balance?.WETH || 0) }}
                  </span>
                </div>
                <div class="flex justify-between items-center">
                  <span class="text-[1.1rem] sm:text-[1.2rem] text-green-400 font-['VT323']">FLAPPY</span>
                  <span class="text-[1.1rem] sm:text-[1.2rem] text-green-400 font-['VT323']">
                    {{ formatTokenAmount(toasterRevenue.balance?.FLAPPY || 0) }}
                  </span>
                </div>
                <div class="text-center pt-3 border-t border-blue-500/10">
                  <p class="text-[0.9rem] text-blue-400/80 font-semibold">
                    ≈ ${{ formatNumber(calculateValue(toasterRevenue.balance)) }}
                  </p>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-2 gap-3">
              <div class="bg-gray-900/30 rounded-lg border border-blue-500/5 p-3">
                <p class="text-[0.8rem] text-blue-400/60 text-center uppercase tracking-wider mb-2">
                  Last 7 Days
                </p>
                <p class="text-[1.1rem] sm:text-[1.2rem] text-green-400 font-['VT323'] text-center">
                +${{ formatNumber(toasterRevenue.weekly) }}
                </p>
              </div>
              <div class="bg-gray-900/30 rounded-lg border border-blue-500/5 p-3">
                <p class="text-[0.8rem] text-blue-400/60 text-center uppercase tracking-wider mb-2">
                  Lifetime
                </p>
                <p class="text-[1.1rem] sm:text-[1.2rem] text-green-400 font-['VT323'] text-center">
                    +${{ formatNumber(toasterRevenue.total) }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Game Revenue -->
        <div class="sub-item w-full bg-gradient-to-br from-gray-900/70 to-gray-800/50 rounded-xl border border-blue-500/10 hover:border-blue-400/30 transition-all duration-300 shadow-lg hover:shadow-blue-500/5">
          <div class="p-4 border-b border-blue-500/10 bg-gray-900/30 h-[132px] flex flex-col justify-center">
            <h3 class="text-white font-['VT323'] text-[1.5rem] sm:text-[1.75rem] text-center text-shadow-sm whitespace-nowrap">
              Game Donations
            </h3>
            <p class="text-blue-400/80 text-[1.1rem] sm:text-[1rem] md:text-[1rem] text-center font-['VT323'] mt-1 line-clamp-2">
              Collected from game entries and in-game purchases
            </p>
          </div>
          
          <div class="p-4">
            <div class="bg-gray-900/40 rounded-lg border border-blue-500/5 p-4 mb-4">
              <p class="text-[0.8rem] text-blue-400/60 text-center uppercase tracking-wider mb-3">
                Current Balance
              </p>
              <div class="space-y-3">
                <div class="flex justify-between items-center">
                  <span class="text-[1.1rem] sm:text-[1.2rem] text-green-400 font-['VT323']">WETH</span>
                  <span class="text-[1.1rem] sm:text-[1.2rem] text-green-400 font-['VT323']">
                    {{ formatTokenAmount(gameRevenue.balance?.WETH || 0) }}
                  </span>
                </div>
                <div class="flex justify-between items-center">
                  <span class="text-[1.1rem] sm:text-[1.2rem] text-green-400 font-['VT323']">FLAPPY</span>
                  <span class="text-[1.1rem] sm:text-[1.2rem] text-green-400 font-['VT323']">
                    {{ formatTokenAmount(gameRevenue.balance?.FLAPPY || 0) }}
                  </span>
                </div>
                <div class="text-center pt-3 border-t border-blue-500/10">
                  <p class="text-[0.9rem] text-blue-400/80 font-semibold">
                    ≈ ${{ formatNumber(calculateValue(gameRevenue.balance)) }}
                  </p>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-2 gap-3">
              <div class="bg-gray-900/30 rounded-lg border border-blue-500/5 p-3">
                <p class="text-[0.8rem] text-blue-400/60 text-center uppercase tracking-wider mb-2">
                  Last 7 Days
                </p>
                <p class="text-[1.1rem] sm:text-[1.2rem] text-green-400 font-['VT323'] text-center">
                    +${{ formatNumber(gameRevenue.weekly) }}
                </p>
              </div>
              <div class="bg-gray-900/30 rounded-lg border border-blue-500/5 p-3">
                <p class="text-[0.8rem] text-blue-400/60 text-center uppercase tracking-wider mb-2">
                  Lifetime
                </p>
                <p class="text-[1.1rem] sm:text-[1.2rem] text-green-400 font-['VT323'] text-center">
                    +${{ formatNumber(gameRevenue.total) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Four flex containers between pools -->
    <div class="flex justify-between w-full">
      <div class="w-1/4 min-h-24 p-4 m-2 "><!-- Connection 1 --></div>
      <div class="w-1/4 min-h-24 p-4 m-2 animated-border"><!-- Connection 2 --></div>
      <div class="w-1/4 min-h-24 p-4 m-2 "><!-- Connection 3 --></div>
      <div class="w-1/4 min-h-24 p-4 m-2 "><!-- Connection 4 --></div>
    </div>

    <!-- Main Pool -->
    <div ref="mainPool" class="main-pool bg-gray-900/90 rounded-xl p-8 w-full border border-blue-500/20 relative z-20">
      <h2 class="text-white font-['VT323'] text-4xl text-center mb-4">The Feeding Ground</h2>
      <p class="text-blue-400/80 text-xl text-center font-['VT323'] leading-relaxed">
        The heart of the ecosystem where resources are distributed efficiently and transparently
      </p>
    </div>

    <!-- After main pool -->
    <!-- First vertical line -->
    <div class="flex justify-between w-full">
      <div class="w-1/4 min-h-24 p-0 m-0"></div>
      <div class="w-1/4 min-h-24 p-0 m-0 animated-border"></div>
      <div class="w-1/4 min-h-24 p-0 m-0"></div>
      <div class="w-1/4 min-h-24 p-0 m-0"></div>
    </div>

    <!-- Horizontal line -->
    <div class="flex justify-between w-full">
      <div class="w-1/4 min-h-1 p-0 m-0"></div>
      <div class="w-2/4 min-h-1 p-0 m-0 animated-border-horizontal"></div>
      <div class="w-1/4 min-h-1 p-0 m-0"></div>
    </div>

    <!-- Two vertical lines -->
    <div class="flex justify-between w-full">
      <div class="w-1/4 min-h-24 p-4 m-0 animated-border"></div>
      <div class="w-1/4 min-h-24 p-4 m-0 "></div>
      <div class="w-1/4 min-h-24 p-4 m-0 animated-border"></div>
      <div class="w-1/4 min-h-24 p-4 m-0"></div>
    </div>

    <!-- Sub Pools -->
    <div class="sub-pools grid grid-cols-2 gap-6 md:gap-8">
      <div class="sub-pool bg-gray-900/90 rounded-xl p-6 w-full border border-blue-500/20 relative z-20">
        <h3 class="text-white font-['VT323'] text-3xl text-center mb-4">Skilled Players</h3>
        <p class="text-blue-400/80 text-xl text-center font-['VT323'] leading-relaxed">
          Top performers in each game round earn the lion's share of the rewards
        </p>
      </div>
      <div class="sub-pool bg-gray-900/90 rounded-xl p-6 w-full border border-blue-500/20 relative z-20">
        <h3 class="text-white font-['VT323'] text-3xl text-center mb-4">Hard-working Birds</h3>
        <p class="text-blue-400/80 text-xl text-center font-['VT323'] leading-relaxed">
          Random players are selected to share in the rewards, ensuring everyone has a chance to benefit
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { computed } from 'vue'

const topPool = ref(null)
const mainPool = ref(null)
const connectionLine = ref(null)
const tConnection = ref(null)

const store = useStore()

// Get token prices from the store
const flappyPrice = computed(() => store.state.flappyPrice || 0)
const wethPrice = computed(() => 3354) // Using fixed WETH price for now

// Debug logs
console.log('FLAPPY Price:', flappyPrice.value)
console.log('WETH Price:', wethPrice.value)

// Helper function to format numbers
const formatNumber = (value) => {
  return Number(value).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

// Add new helper function to format token amounts
const formatTokenAmount = (amount) => {
  return Number(amount).toLocaleString('en-US', {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4
  });
};

// Updated computed properties to handle all three metrics
const tradingRevenue = computed(() => {
  const data = store.state.revenue.tradingFees;
  return {
    balance: data.balance,
    weekly: calculateValue(data.weekly),
    total: calculateValue(data.total)
  };
});

const toasterRevenue = computed(() => {
  const data = store.state.revenue.fundManager;
  return {
    balance: data.balance,
    weekly: calculateValue(data.weekly),
    total: calculateValue(data.total)
  };
});

const gameRevenue = computed(() => {
  const data = store.state.revenue.gameRevenue;
  return {
    balance: data.balance,
    weekly: calculateValue(data.weekly),
    total: calculateValue(data.total)
  };
});

// Helper function to calculate values
const calculateValue = (data = {}) => {
  if (!flappyPrice.value || !data) return '0.00';
  const wethValue = (data.WETH || 0) * wethPrice.value;
  const flappyValue = (data.FLAPPY || 0) * flappyPrice.value;
  return (wethValue + flappyValue).toFixed(2);
};

const updateConnectionLines = () => {
  // Update top connection
  if (topPool.value && connectionLine.value) {
    const topRect = topPool.value.offsetHeight
    const distance = 128 // This is the mb-32 value in pixels
    
    connectionLine.value.style.top = `${topRect}px`
    const dashedLine = connectionLine.value.querySelector('.dashed-line')
    if (dashedLine) {
      dashedLine.style.height = `${distance}px`
    }
  }

  // Update T-connection
  if (mainPool.value && tConnection.value) {
    const mainRect = mainPool.value.offsetHeight
    const distance = 128 // This is the mb-32 value in pixels
    
    tConnection.value.style.top = `${mainRect}px`
    
    const verticalLine = tConnection.value.querySelector('.vertical-line')
    const horizontalLine = tConnection.value.querySelector('.horizontal-line')
    const endLines = tConnection.value.querySelectorAll('.end-line')
    
    if (verticalLine && horizontalLine && endLines) {
      verticalLine.style.height = `${distance}px`
      horizontalLine.style.top = `${distance}px`
      endLines.forEach(line => {
        line.style.top = `${distance}px`
      })
    }
  }
}

// Add new ref for loading state
const isLoading = ref(true)

// Update the onMounted hook
onMounted(async () => {
  try {
    await store.dispatch('fetchFlappyPrice')
    await store.dispatch('revenue/fetchRevenue')
    updateConnectionLines()
    window.addEventListener('resize', updateConnectionLines)
  } catch (error) {
    console.error('Failed to fetch data:', error)
  } finally {
    isLoading.value = false
  }
})

onUnmounted(() => {
  window.removeEventListener('resize', updateConnectionLines)
})
</script>

<style scoped>
.prize-distribution {
  min-height: 100vh;
}

/* Single vertical connection */
.connection-line {
  position: absolute;
  top: 220px;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 128px;
}

.dashed-line {
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    to bottom,
    rgba(59, 130, 246, 0.5) 0,
    rgba(59, 130, 246, 0.5) 8px,
    transparent 8px,
    transparent 16px
  );
  animation: dashMove 1s linear infinite;
}

/* Updated T-connection with end caps */
.t-connection {
  position: absolute;
  top: 520px;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
}

.t-connection .vertical-line {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 60px;
  background: repeating-linear-gradient(
    to bottom,
    rgba(59, 130, 246, 0.5) 0,
    rgba(59, 130, 246, 0.5) 8px,
    transparent 8px,
    transparent 16px
  );
  animation: dashMove 1s linear infinite;
}

.t-connection .horizontal-line {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  height: 2px;
  background: repeating-linear-gradient(
    to right,
    rgba(59, 130, 246, 0.5) 0,
    rgba(59, 130, 246, 0.5) 8px,
    transparent 8px,
    transparent 16px
  );
  animation: dashMoveHorizontal 1s linear infinite;
}

.t-connection .end-lines {
  position: absolute;
  top: 60px;
  width: 100%;
}

.t-connection .end-line {
  position: absolute;
  width: 2px;
  height: 80px;
  background: repeating-linear-gradient(
    to bottom,
    rgba(59, 130, 246, 0.5) 0,
    rgba(59, 130, 246, 0.5) 8px,
    transparent 8px,
    transparent 16px
  );
  animation: dashMove 1s linear infinite;
}

.t-connection .end-line.left {
  left: 0;
}

.t-connection .end-line.right {
  right: 0;
}

@keyframes dashMove {
  from { background-position: 0 0; }
  to { background-position: 0 32px; }
}

@keyframes dashMoveHorizontal {
  from { background-position: 0 0; }
  to { background-position: 32px 0; }
}

/* Hide on mobile */
@media (max-width: 768px) {
  .connection-line,
  .t-connection {
    display: none;
  }
}

.animated-border {
  background-image: linear-gradient(
    to bottom,
    rgba(59, 130, 246, 0.5) 50%,
    transparent 50%
  );
  background-size: 2px 16px;
  background-position: right;
  background-repeat: repeat-y;
  animation: borderDashMove 1s linear infinite;
}

@keyframes borderDashMove {
  from { background-position-y: 0; }
  to { background-position-y: 16px; }
}

.animated-border-horizontal {
  background-image: linear-gradient(
    to right,
    rgba(59, 130, 246, 0.5) 50%,
    transparent 50%
  );
  background-size: 16px 2px;
  background-position: top;
  background-repeat: repeat-x;
  animation: borderDashMoveHorizontal 1s linear infinite;
}

@keyframes borderDashMoveHorizontal {
  from { background-position-x: 0; }
  to { background-position-x: 16px; }
}

.text-shadow-glow {
  text-shadow: 0 0 10px rgba(59, 130, 246, 0.5),
               0 0 20px rgba(59, 130, 246, 0.3);
}

.text-shadow-sm {
  text-shadow: 0 0 5px rgba(59, 130, 246, 0.3);
}

.prize-distribution {
  background: radial-gradient(circle at center, rgba(30, 41, 59, 0.5) 0%, rgba(15, 23, 42, 0.3) 100%);
}

.sub-item {
  height: fit-content;
  min-height: 360px;
  display: flex;
  flex-direction: column;
}

.sub-item > div:last-child {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 640px) {
  .sub-item {
    min-height: 340px;
  }
}

/* Hover animations */
.sub-item {
  transition: all 0.3s ease;
}

.sub-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 20px rgba(59, 130, 246, 0.1);
}

/* Ensure consistent heights for balance boxes */
.bg-gray-900\/40 {
  min-height: 160px;
  display: flex;
  flex-direction: column;
}

.bg-gray-900\/40 > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.pulsating-link {
  position: relative;
  color: #60A5FA; /* blue-400 */
  text-decoration: underline;
  animation: pulsate 2s ease-in-out infinite;
}

@keyframes pulsate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

/* Optional: Add hover effect */
.pulsating-link:hover {
  color: #93C5FD; /* blue-300 */
  text-shadow: 0 0 8px rgba(96, 165, 250, 0.4);
}
</style> 