<template>
  <nav class="w-full bg-black border-b border-blue-500/30 px-4 py-3">
    <div class="max-w-7xl mx-auto flex items-center">
      <!-- Left: Logo -->
      <router-link to="/" class="text-[#4287f5] text-4xl font-['VT323'] hover:text-blue-400 transition-colors mr-12">
        FLAPPYETH
      </router-link>

      <!-- Center: Navigation (Desktop) -->
      <div class="hidden md:flex space-x-6">
        <router-link 
          v-for="link in visibleNavLinks" 
          :key="link.path"
          :to="link.path" 
          class="nav-link text-left"
          :class="{ 'active': $route.path === link.path }"
        >
          {{ link.name }}
        </router-link>
      </div>

      <!-- Right: Wallet Section -->
      <div class="flex items-center space-x-4 ml-auto">
        <!-- Network Status -->
        <div v-if="isWalletConnected" class="hidden md:flex items-center bg-blue-500/10 rounded-lg px-3 py-1">
          <span class="h-2 w-2 rounded-full bg-green-400 mr-2"></span>
          <span class="text-lg text-blue-400 font-['VT323']">BLAST</span>
        </div>
        <WalletConnectButton />
        
        <!-- Mobile Menu Button -->
        <button @click="isMobileMenuOpen = !isMobileMenuOpen" class="md:hidden text-blue-400">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path v-if="!isMobileMenuOpen" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            <path v-else stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>

    <!-- Mobile Navigation Menu -->
    <div v-if="isMobileMenuOpen" class="md:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1">
        <router-link
          v-for="link in visibleNavLinks"
          :key="link.path"
          :to="link.path"
          class="block px-3 py-2 rounded-md text-base font-['VT323'] transition-colors"
          :class="{ 'text-blue-400 bg-blue-500/10': $route.path === link.path, 'text-gray-400 hover:text-blue-400': $route.path !== link.path }"
          @click="isMobileMenuOpen = false"
        >
          {{ link.name }}
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import WalletConnectButton from './WalletConnectButton.vue'
import { computed, ref } from 'vue'
import { account } from "@kolirt/vue-web3-auth"

export default {
  components: {
    WalletConnectButton
  },
  data() {
    return {
      navLinks: [
        { name: 'Play', path: '/play', visible: false },
        { name: 'Rankings', path: '/rankings', visible: false },
        { name: 'Nest', path: '/nest', visible: true },
        { name: 'FAQ', path: '/faq', visible: true }
      ]
    }
  },
  computed: {
    visibleNavLinks() {
      return this.navLinks.filter(link => link.visible)
    }
  },
  setup() {
    const isWalletConnected = computed(() => account.connected)
    const isMobileMenuOpen = ref(false)
    
    return {
      isWalletConnected,
      isMobileMenuOpen
    }
  }
}
</script>

<style scoped>
.nav-link {
  @apply text-gray-400 hover:text-blue-400 transition-colors duration-200 font-['VT323'] text-lg;
  text-shadow: 0 0 8px rgba(66, 135, 245, 0.3);
}

.nav-link.active {
  @apply text-[#4287f5];
  text-shadow: 0 0 12px rgba(66, 135, 245, 0.5);
}

.wallet-button {
  @apply px-3 py-1.5 font-['VT323'] text-blue-400 hover:bg-blue-500/20 transition-colors;
}

nav {
  position: relative;
  box-shadow: 
    0 2px 0 rgba(66, 135, 245, 0.2),
    0 4px 0 rgba(66, 135, 245, 0.1);
}

nav::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(66, 135, 245, 0.3),
    rgba(66, 135, 245, 0.3),
    transparent
  );
}
</style>
