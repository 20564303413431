<template>
  <div class="min-h-screen flex flex-col bg-black">
    <AppNavigation v-if="!isLocked"></AppNavigation>

    <!-- Pre-launch Screen -->
    <div
      v-if="isLocked"
      class="flex-grow flex items-center justify-center bg-neutral-900 relative"
      :style="{
        backgroundImage: 'url(' + require('@/assets/bg_locked.png') + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }"
    >
      <div class="absolute inset-0 bg-black bg-opacity-60"></div>
      <div class="text-center p-8 relative z-10">
        <h1 class="text-4xl font-bold text-custom-orange mb-6">
          MAINTENANCE MODE
        </h1>
        <p class="text-xl text-gray-300 mb-8">Preparing for the launch!</p>
        <div class="flex gap-6 justify-center">
          <a
            href="https://app.baseline.markets/trade/blast/0x1BB5E4Dd6F18E8941fCe273379DF2223cD08C2da"
            target="_blank"
            class="px-6 py-3 bg-custom-orange text-white rounded-lg hover:bg-orange-600 transition-colors"
          >
            Trade on Baseline Markets
          </a>
        </div>
      </div>
    </div>

    <!-- Main Content -->
    <div v-else class="flex-grow">
      <router-view></router-view>
    </div>

    <FooterDisplay v-if="!isLocked" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import AppNavigation from "./components/AppNavigation.vue";
import FooterDisplay from "./components/FooterDisplay.vue";

export default {
  name: "App",
  components: {
    AppNavigation,
    FooterDisplay,
  },
  data() {
    return {
      isLocked: process.env.VUE_APP_LOCKED === "true",
    };
  },
  computed: {
    ...mapState(["isInitialized"]),
  },
};
</script>

<style>
:deep(html) {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

:deep(footer) {
  margin-top: auto;
}
</style>

